import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MetaBloxLogo from "@@/assets/metablox-logo1.png"
import YLine from "@@/assets/imgs/y-line.png"
import BoxChecked from "@@/assets/icons/box_checked.svg"
import BoxUnChecked from "@@/assets/icons/box_unchecked.svg"
import Button from "@@/components/common/Button";
import {
    firebaseEmailSignIn,
    firebaseEmailSignUp,
    firebaseSendEmailVerification, firebaseSendPasswordResetEmail
} from "@@/service/firebaseEmail";
import {
    cacheStoreInfo,
    regEmail,
    setJWTToken, setLocalDictData,
    setLocalUserInfo
} from "@@/utils/function";
import { getDiCtData, getUserInfo, welcome} from "@@/utils/request/api";
import Popup from "reactjs-popup";
import PopupAlert from "@@/pages/PopupAlert";
import sdstorage from "@@/utils/db/localStorage";
import AntInputBlock from "@@/components/common/AntInputBlock";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
type Props = {};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  margin: 140px 0 0 0;

  .login_top {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MetaBloxLogo {
      width: 265px;

    }
    .YLine{
      height: 17px;
      margin: 10px 40px 0 40px;
    }
    .system{
      color: #FFFFFFCC;
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 57px;
    }

  }

  .login_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    .sign_tab {
      display: flex;
      flex-direction: row;
      margin: 85px 0 52px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 32px;
      justify-content: center;
      gap: 57px;
      color: rgba(255, 255, 255, 0.8);
      
      .un_active{
        color: #555555;
      }
      .active{
       border-bottom: #7357E7 4px solid;
      }
    }
    
    .sign_up_tips{
      width: 450px;
      margin-bottom: 50px;
      text-align: center;
    }
    
    .sign_error{
      width: 450px;
      margin-bottom: 50px;
      text-align: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #FF0000;
    }
    .sign_input{
      //width: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .remember_reset {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 32px 0 0 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #FFFFFF;
      width: 328px;

      .remember_reset_left {
        display: flex;
        flex-direction: row;

        .remember_reset_checkbox {
          width: 20px;
          margin-right: 6px;
          cursor: pointer;
          //height: 24px;
          img {
            width: 100%;
          }
        }

      }

      .remember_reset_right {
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }
`;

const App =  (props: Props) => {
    const btnCss = {margin:'48px 0 0 0',padding:'5px 48px',line_height:'38px',width:'328px',border_radius:'16px',font_size:'16px'};
    const antInputCss = {width:328,height:48,font_size:'20px'};
    const navigate = useNavigate();
    const rePass = sdstorage.get('remember_password');
    const [isRememberPassword,setIsRememberPassword] = useState(typeof rePass?.is_remember !== 'undefined' ? rePass?.is_remember : false)
    const [allowLogin,setAllowLogin] = useState(false)
    const [allowLogin0,setAllowLogin0] = useState(false)
    const [username,setUsername] = useState(typeof rePass?.data?.username !== 'undefined' ? rePass?.data?.username : '')
    const [password,setPassword] = useState(typeof rePass?.data?.password !== 'undefined' ? rePass?.data?.password : '')
    const [username0,setUsername0] = useState('')
    const [password0,setPassword0] = useState('')
    const [popupMsg,setPopupMsg] = useState('')
    const [signType,setSignType] = useState('signIn')
    const [openPopup, setOpenPopup] = useState(false);
    const [signInError, setSignInError] = useState('');
    const [signUpError, setSignUpError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const changeRememberPassword = () => {
        setIsRememberPassword(!isRememberPassword)
    }

    const openPopupAlert = (msg:string) => {
        setOpenPopup(true);
        setPopupMsg(msg)
    }

    const sendResetPasswordLink = async () => {
        if( username?.length <= 0 ){
            setSignInError('Please enter email!')
            return false;
        }
        const resEmail = regEmail(username);
        if(!resEmail.status){
            setSignInError(resEmail?.msg)
            return false;
        }
        const res0 = await firebaseSendPasswordResetEmail(username)
        if(res0?.code !== 200){
            setSignInError(res0?.msg)
            return false;
        }
        openPopupAlert('Email sent successfully, please reset your password!')
        return false;
    }
    const SubmitSignIn = async () => {
        if( username?.length <= 0 ){
            setSignInError('Please enter email!')
            return false;
        }
        if( password?.length <= 0 ){
            setSignInError('Please enter password!')
            return false;
        }

        const resEmail = regEmail(username);
        if(!resEmail.status){
            setSignInError(resEmail?.msg)
            return false;
        }
        setIsLoading(true)
        const res = await firebaseEmailSignIn(username,password);
        if(res?.code !== 200){
            setSignInError(res?.msg)
            setIsLoading(false)
            // setSignUpError(res?.msg === 'Wrong password!' ? 'The email you entered is not associated with any account. Please register or provide a different email.':res?.msg)
            return false;
        }

        if(res?.data?.emailVerified !== true){
            setSignInError('Please go to the email to activate your account!')
            setIsLoading(false)
            return false;
        }

        setJWTToken(await res?.data?.getIdToken())

        const res2 = await welcome()
        if(res2?.code !== 200){
            setSignInError(res2?.msg)
            setIsLoading(false)
            return false;
        }

        const res1 = await getUserInfo();
        if(res1?.code !== 200){
            setSignInError(res1?.msg)
            setIsLoading(false)
            return false;
        }
        const res3 = await getDiCtData();
        if(res3?.code !== 200){
            setSignInError(res3?.msg)
            setIsLoading(false)
            return false;
        }
        setLocalDictData(res3?.data)

        await cacheStoreInfo()

        if(isRememberPassword){
            sdstorage.save('remember_password',{is_remember:isRememberPassword,data:{username:username,password:password}})
        }
        else{
            sdstorage.remove('remember_password')
        }

        res1.data.role = 'admin';
        setLocalUserInfo(res1?.data)
        setIsLoading(false)
        if(res1?.data?.approved === true){
            navigate("/")
        }
        else {
            navigate('/settings/account')
        }

    }

    const SubmitSignUp = async () => {
        if( username0?.length <= 0 ){
            setSignUpError('Please enter email!')
            return false;
        }
        if( password0?.length <= 0 ){
            setSignUpError('Please enter password!')
            return false;
        }

        const resEmail = regEmail(username0);
        if(!resEmail.status){
            setSignUpError(resEmail?.msg)
            return false;
        }
        const res = await firebaseEmailSignUp(username0,password0);
        if(res?.code !== 200){
            setSignUpError(res?.msg === 'The email has been registered!' ? 'An account with this email already exists. Please sign in or choose a different email.':res?.msg)
            return false;
        }
        const res0 = await firebaseSendEmailVerification();
        if(res0?.code !== 200){
            setSignUpError(res0?.msg)
            return false;
        }
        openPopupAlert("We're happy you signed up for the Merchant Platform. To start exploring the Merchant Platform, please confirm your email address. ")
    }

    useEffect(() => {
    }, []);

    useEffect(() => {
        if(username?.length > 0 && password?.length > 0){
            setAllowLogin(true)
        }
        else {
            setAllowLogin(false)
        }

        if(username0?.length > 0 && password0?.length > 0){
            setAllowLogin0(true)
        }
        else {
            setAllowLogin0(false)
        }
    }, [username,password,username0,password0]);
    return (
        <Spin indicator={antIcon} spinning={isLoading} >
            <Wrapper>

                <Popup
                    open={openPopup}
                    closeOnDocumentClick={false}
                    onClose={()=>setOpenPopup(false)}
                    overlayStyle = {{ background: 'rgba(0,0,0,0.8)' }}
                >
                    <PopupAlert closePopup={()=>setOpenPopup(false)} msgContent={popupMsg}/>
                </Popup>
                <div className="login_top">
                    <img src={MetaBloxLogo} alt="MetaBloxLogo" className="MetaBloxLogo"/>
                    <img src={YLine} alt="YLine" className="YLine"/>
                    <div className="system">Merchant portal</div>
                </div>
                <div className="login_center">
                    <div className="sign_tab">
                        <div className={`sign_up ${signType === 'signIn' ? 'un_active cursor-pointer' : 'active'}`} onClick={()=>setSignType('signUp')}>Sign Up</div>
                        <div className={`sign_in ${signType === 'signIn' ? 'active' : 'un_active cursor-pointer'}`} onClick={()=>setSignType('signIn')}>Sign In</div>
                    </div>

                    {signType === 'signIn' && (
                        <div className="sign_input">
                            {signInError !=='' &&
                                <div className="sign_error">{signInError}</div>
                            }


                            <AntInputBlock
                                title='Email'
                                antInputPlaceholder={'Enter your email'}
                                antInputCss={antInputCss}
                                value={username}
                                antInputAllowClear={true}
                                antInputOnChange={(ee:any)=>{
                                    setUsername(ee.target.value)
                                    setSignInError('')
                                }}
                            />

                            <AntInputBlock
                                antInputType='password'
                                title='Password'
                                antInputPlaceholder={'Enter your Password'}
                                antInputCss={antInputCss}
                                value={password}
                                antInputAllowClear={true}
                                antInputOnChange={(ee:any)=> {
                                    setPassword(ee.target.value)
                                    setSignInError('')
                                }}
                            />


                            <div className="remember_reset">
                                <div className="remember_reset_left">
                                    <div className="remember_reset_checkbox" onClick={changeRememberPassword}>
                                        {isRememberPassword ? <img src={BoxChecked} alt="BoxChecked"/> : <img src={BoxUnChecked} alt="BoxUnChecked"/>}
                                    </div>
                                    <div>Remember password</div>
                                </div>
                                <div className="remember_reset_right" onClick={sendResetPasswordLink}>
                                    Reset password
                                </div>
                            </div>
                        </div>
                    )}

                    {signType === 'signUp' && (
                        <div className="sign_input">
                            <div className="sign_up_tips">To register on our merchant portal, you need to send us an request. After approval, you will receive an email notification that your email has been approved.</div>
                            {signUpError !== '' &&
                                <div className="sign_error">{signUpError}</div>
                            }
                            <AntInputBlock
                                title='Email'
                                antInputPlaceholder={'Enter your email'}
                                antInputCss={antInputCss}
                                value={username0}
                                antInputAllowClear={true}
                                antInputOnChange={(ee:any)=> {
                                    setUsername0(ee.target.value)
                                    setSignUpError('')
                                }}
                            />

                            <AntInputBlock
                                antInputType='password'
                                title='Password'
                                antInputPlaceholder={'Create your password'}
                                antInputCss={antInputCss}
                                value={password0}
                                antInputAllowClear={true}
                                antInputOnChange={(ee:any)=> {
                                    setPassword0(ee.target.value)
                                    setSignUpError('')
                                }}
                            />
                        </div>
                    )}

                </div>
                {signType === 'signIn' && (
                    <Button SubmitClick={SubmitSignIn} allowClick={allowLogin}  title={'Sign In'} cssStyle={btnCss}></Button>
                )}
                {signType === 'signUp' && (
                    <Button SubmitClick={SubmitSignUp} allowClick={allowLogin0}  title={'Submit'} cssStyle={btnCss}></Button>
                )}
            </Wrapper>
        </Spin>
    );
};

export default App;
