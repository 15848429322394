import React, {useEffect, useState} from "react";
import styled from "styled-components";
import HeadPic from "@@/assets/imgs/head_pic.png";
import {
    getLocalStoreInfo,
    getLocalStoreInfo1, menuList
} from "@@/utils/function";
import 'react-tooltip/dist/react-tooltip.css';
import {useNavigate} from "react-router-dom";
import {leftMenuProps} from "@@/type";
import {Menu} from "antd";
import {getApplyDetail} from "@@/utils/request/api";

const LeftMenuGroup = styled.div`

  background: #0f0035;
  padding: 27px 0 0 0;
  width: 283px;
  min-height: 100vh;

  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #B8B8B8;

  .menu-container {
    background: #0f0035;
  }
  .ant-menu-submenu-open{
 
  }
  .ant-menu,.ant-menu-sub,.ant-menu-inline{
    background: #0f0035!important;
  }
  //
  //.sub-menu-container {
    //background: #0f0035;
  //}
  //.menu-item-container {
  //}

  
`;
//
// type routerType = {
//     title:string
//     url:string
//     nav:string
//     icon?:any
//     child?:routerType[]
// }

const LeftMenu = (
    {
        refreshPage,
        ActiveNav = 'dashboard',
    }:leftMenuProps) => {
    const collapsed = false
    // const [collapsed, setCollapsed] = useState(false);

    // const toggleCollapsed = () => {
    //     setCollapsed(!collapsed);
    // };

    const [storeInfo, setStoreInfo] = useState<any>(getLocalStoreInfo1());
    const [navList, setNavList] = useState<any[]>([]);
    const navigate = useNavigate();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    const selectMenu =(items:any) => {
        // 选中菜单
        const {key,keyPath} = items
        setOpenKeys([keyPath[keyPath.length - 1]])
        setSelectedKeys([key])
        navigate(key)
    }
    const openMenu = (openKeys:string[]) => {
        // 展开
        setOpenKeys([openKeys[openKeys.length - 1]])
    }

    // // 处理一级菜单栏
    // const  renderMenu =(item: routerType) => {
    //     return (
    //         <Menu.Item
    //             className="menu-item-container"
    //             key={item.url} icon={item.icon==='' ? '':item.icon}>
    //             <Link to={item.url}>
    //                 <span>{item.title}</span>
    //             </Link>
    //         </Menu.Item>
    //     )
    // }

    // // 处理子级菜单栏
    // const renderSubMnenu = (item: routerType) => {
    //     return (
    //         <SubMenu
    //             className='sub-menu-container'
    //             key={item.url} title={item.title} icon={item.icon==='' ? '':item.icon}>
    //             {
    //                 item?.child?.map((ii:any) => {
    //                     return ii.child && ii.child.length > 0 ? renderSubMnenu(ii) : renderMenu(ii)
    //                 })
    //             }
    //         </SubMenu>
    //     )
    //
    // }

    const initMenu = () => {
        const res0 = getLocalStoreInfo();
        if(res0 !== ''){
            if(res0.approved === false){
                setNavList(menuList(2))
            }
            else {
                setNavList(menuList())
            }
            return false
        }


        getApplyDetail().then((res:any)=>{
            const {code,data} = res
            if(code === 200){
                if(data.approved === false){
                    setNavList(menuList(2))
                }
                else {
                    setNavList(menuList())
                }
            }

        });
    }

    useEffect(() => {
        // 菜单状态
        const pathname = window.location.pathname;
        const menuKey = pathname.split("/").slice(0,2).join('/');

        setOpenKeys([menuKey])
        setSelectedKeys([pathname])

        initMenu()
    }, []);

    useEffect(() => {
        setStoreInfo(getLocalStoreInfo1());
    }, [refreshPage]);
    return (
        <LeftMenuGroup>
            <div className="flex flex-row gap-[10px] pl-[32px]">
                <div className="">
                    <img className="w-[45px] h-[45px] rounded-[22px]" src={getLocalStoreInfo1()?.approved === false ? HeadPic:(storeInfo?.logo ?? HeadPic)} alt="HeadPic"/>
                </div>
                <div className='flex flex-col gap-[10px]'>
                    <div className='text-[12px] leading-[16px] text-[#EBEBEB]'>Your store</div>
                    <div className="w-[160px] uppercase text-[#ffffff]  text_overflow">{getLocalStoreInfo1()?.approved === false ? 'Store Name' :(storeInfo?.storeName ?? 'Store Name')}</div>
                </div>
            </div>
            <div className="flex flex-col gap-[50px] mt-[30px] text-[#ffffff]">
                {/*<Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>*/}
                {/*    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}*/}
                {/*</Button>*/}
                <Menu
                    className="menu-container"
                    onOpenChange={openMenu}
                    onClick={selectMenu}
                    theme="dark"
                    mode="inline"
                    selectedKeys={selectedKeys}
                    openKeys={openKeys}
                    inlineCollapsed={collapsed}
                    items={navList}
                    style={{ height: '100%', borderRight: 0 }}
                >
                </Menu>
            </div>
        </LeftMenuGroup>
    );


};

export default LeftMenu;
