import React, {ReactNode, useEffect} from "react";
import {  Header } from "@@/components";
import styled from "styled-components";
import LeftMenu from "@@/components/account/LeftMenu";
import MainContent from "@@/components/layout/Main";

const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  background-color: #07001b;
  width: 100%;
  font-size: 20px;
  line-height: 27px;
  font-weight: 800;
  
  .main_container{
    margin: 60px 120px 0 40px;
    color: #FFFFFF;
    
    .card{
      min-width: 1000px;
      
      .card_grey{
        opacity: 0.45;
      }
      
      .card_container{
        background: #201c31;
        border: 1px solid #464255;
        border-radius: 8px;
        width: 50%;
        padding: 25px;

        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
        
        .header{
          
          .header_left{
            .header_left1{
              width: 25px;
            }
          }
          
          .header_center{
            .header_center1{
              width: 19px;
              height: 18px;
            }
          }
          .header_right_div{
            width: 20px;
            
            .header_right{
              width: 6px;
              height:12px;
            }
            .header_right0{
              width: 12px;
              height:6px;
            }
          }
        
        }

        .content{
          font-size: 26px;
          line-height: 36px;
          .content1{
          }
          .content2{

            font-weight: 600;
          }
        }
        
        .bottom{
          font-family: 'Avenir';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: gray;
          
          .transaction_right2{
            width: 10px;
            height: auto;
          }
          
          .bottom_right{
            height: 45px;
          }
      
        }
      }
    }
    
    .detail{
      min-width: 1000px;

    }
  }
`;

const PageLayout = ({
                        children,
                        activeNav = 'dashboard'
                    }:
                        {
                            children: ReactNode;
                            activeNav?:string
                        }) => {
    // const [userInfo, setUserInfo] = useState<any>();
    const initUser = async () => {
        // setUserInfo(await cacheUserInfo())
    }

    useEffect(() => {
        initUser()
    }, []);

    return (
        <div>
            <Header />
            <MainContent>
                <LeftMenu ActiveNav={activeNav}/>
                <MainPanel>
                    {children}
                </MainPanel>
            </MainContent>

        </div>
    );
};

export default PageLayout;
