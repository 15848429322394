import React from "react";
import styled from "styled-components";
import {ConfigProvider, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";

const BlockGroup = styled.div.attrs((props:{
}) => ({
}))`
 

`;

type Props = {
    antInputPlaceholder?:string;
    value?:any;
    defaultValue?:any;
    antInputOnChange?:any
    antInputOnPressEnter?:any
};
const AntSearchInputBlock = ({
                           antInputPlaceholder='Search',
                           value=null,
                           defaultValue=null,
                           antInputOnChange = null,
                           antInputOnPressEnter = null,
                       }:Props) => {


    return (
        <BlockGroup>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#DBDBDB',
                        // colorBgBase:'rgb(32, 28, 49)',
                        colorTextBase:'#ffffff',
                    },
                }}
            >
                <Input
                    onChange={antInputOnChange}
                    value={value}
                    onPressEnter={antInputOnPressEnter}
                    size="large"
                    style={{width:'60%'}}
                    placeholder={antInputPlaceholder}
                    defaultValue={defaultValue}
                    prefix={<SearchOutlined className="site-form-item-icon" />}
                />
            </ConfigProvider>
        </BlockGroup>

    );
};

export default AntSearchInputBlock;
