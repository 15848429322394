import React from 'react';
import * as pages from '@@/pages/Index';
import { router } from '@@/router';
import { toPascalCase } from '@@/utils/function';
import Login from "@@/pages/login";
type RouterType = {
    path:string
    element:any
    allowedRoles:any
}
export const routerConfig:RouterType[] = router.reduce(
    (prev, current) => {
        if((current?.code ?? '') !== ''){
            const Comp = (pages as any)[toPascalCase(current.code)];
            return [...prev, { path: current.key, element: <Comp /> ,allowedRoles:current.allowedroles ?? []}];
        }
        else {

            let currentList: RouterType[] = []
            current?.children?.map((item:any,index)=> {
                if((item?.code ?? '') !== ''){
                    const Comp1 = (pages as any)[toPascalCase(item.code)];
                    currentList = [...currentList, { path: item.key, element: <Comp1 /> ,allowedRoles:item.allowedroles ?? []}]
                }
                return []
            })
            return [...prev,...currentList]
        }
    },
    [
        { path: '/login', element: <Login /> ,allowedRoles:[]},
    ],
);
