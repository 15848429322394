import React from "react";
import styled from "styled-components";
import {Select} from "antd";
import {IconX0} from "@@/utils/function";

const BlockGroup = styled.div.attrs((props:{
    width?:string,
    error?:boolean,
    background_image?:string,

}) => ({
    width: props?.width || '490px',
    error: props?.error || false,
    background_image: props?.error ?'linear-gradient(324.05deg, #CF1515, #CF1515, #CF1515)':(props?.background_image || "linear-gradient(324.05deg, #A031E4 -8.98%, #7357E7 31.12%, #4EABF0 98.56%)") ,
}))`
  margin: 0 0 6px 0;

  .select_title{
    height: 21px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 5px;
  }
  
  .ant_error{
    width: ${(props) => props?.width};
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding-top: 6px;
    color: #FF0000;
  }


`;

type Props = {
    title?:string;
    selectCss?:any
    placeholder?:string;
    defaultValue?:any;
    allowClear?:boolean;
    disabled?:boolean;
    onChange?:any;
    antSelectOnBlur?:any;
    fieldNames?: any;
    options?:any;
    errorData?: {
        error?:boolean;
        message?:string;
    }|null;
};
const AntSelectBlock = ({
                         title = '',
                         selectCss,
                         placeholder = '',
                         defaultValue = '',
                         allowClear = false,
                         disabled = false,
                         onChange = null,
                            antSelectOnBlur = null,

                            fieldNames,
                         options = [],
                            errorData = null,

                        }:Props) => {


    return (
        <BlockGroup
            {...selectCss}
        >

            <div className={'select_title'}>{title}</div>
            <Select
                bordered={false}
                style={ (errorData?.error && errorData?.message) ? {...selectCss,borderColor:'rgb(207, 21, 21)'}:selectCss}
                placeholder={placeholder}
                defaultValue={defaultValue}
                allowClear={allowClear}
                disabled={disabled}
                onChange={onChange}
                fieldNames={fieldNames}
                options={options}
                onBlur={antSelectOnBlur}
                clearIcon={IconX0}

            />
            {errorData?.error && errorData?.message &&
                <div className={'ant_error'}>{errorData?.message}</div>
            }

        </BlockGroup>

    );
};

export default AntSelectBlock;
