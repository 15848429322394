import React from "react";
import styled from "styled-components";
import {Input} from "antd";
import { IconX} from "@@/utils/function";

const BlockGroup = styled.div.attrs((props:{
    error?:boolean,
    width?:number,
    height?:number,
    border_radius?:string,
    font_size?:string,
    title_font_size?:string,
    background?:string,
    background_image?:string,

}) => ({
    error: props?.error || false,
    width: props?.width || 490,
    height: props?.height || 34,
    border_radius: props?.border_radius || '8px',
    font_size: props?.font_size || "14px",
    title_font_size: props?.title_font_size || "14px",
    background: props?.background || "rgba(38, 25, 68)",
    background_image: props?.error ?'linear-gradient(324.05deg, #CF1515, #CF1515, #CF1515)':(props?.background_image || "linear-gradient(324.05deg, #A031E4 -8.98%, #7357E7 31.12%, #4EABF0 98.56%)") ,
}))`
  margin: 0 0 6px 0;

  .ant_input_title{
    height: 21px;
    font-weight: 500;
    font-size: ${(props) => props?.title_font_size};
    line-height: 21px;
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 5px;
  }


  .ant_input_container{
    width:${(props) => (props?.width + 2) + 'px'};
    height:${(props) => (props?.height + 2) + 'px'};
    background-image: ${(props) => props?.background_image};
    border-radius: ${(props) => props?.border_radius};

    .ant_input_container00{
      width: ${(props) => props?.width+ 'px'};
      height: ${(props) => props?.height+ 'px'};
      background: ${(props) => props?.background};
      border-radius: ${(props) => props?.border_radius};
    }

    .imgInputX{
      width: 12px;
    }


    .ant-input-affix-wrapper{
      width: ${(props) => (props?.width-1)+ 'px'};
      height: ${(props) => (props?.height-1)+ 'px'};
      padding: 0;
      border-radius: ${(props) => props?.border_radius};

      input{
        width: ${(props) => (props?.width-1)+ 'px'};
        height: ${(props) => (props?.height-1)+ 'px'};
        background: ${(props) => props?.background};
        border-radius: ${(props) => '15px'};
        //caret-color: rgb(255, 255, 255);
        font-size: ${(props) => props?.font_size};
        color: rgba(255, 255, 255, 0.8);
        padding-left: 11px;
        padding-right: 11px;
      }

    }
    .ant-input-suffix{

      svg{
        //font-size: 14px;
        color: #ffffffcc;
        background-color: #242034;
      }

      .ant-input-clear-icon{
        //font-size: 14px;
        color: #ffffffcc;
        //background-color: #242034;
      }      position: relative;
      left: -10px;
    }

    input{
      width: ${(props) => props?.width+ 'px'};
      height: ${(props) => props?.height+ 'px'};
      background: ${(props) => props?.background};
      border-radius: ${(props) => props?.border_radius};
      //caret-color: rgb(255, 255, 255);
      font-size: ${(props) => props?.font_size};
      color: rgba(255, 255, 255, 0.8);

    }
    input::placeholder{
      color: rgba(255, 255, 255, 0.2);
    }

    input:-internal-autofill-previewed,
    input:-internal-autofill-selected {
      font-size: ${(props) => props?.font_size};
      -webkit-text-fill-color:rgba(255, 255, 255, 0.8);
      transition: background-color 5000s ease-out 0.5s;
    }
  }


  .ant_error{
    width: ${(props) => (props?.width-1)+ 'px'};
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding-top: 6px;
    color: #FF0000;
  }

`;

type Props = {
    title?:string;
    antInputPlaceholder?:string;
    antInputType?:string;
    antInputAllowClear?:boolean;
    value?:any;
    defaultValue?:any;
    antInputCss?:any
    antInputOnChange?:any
    antInputOnBlur?:any
    // allowClear?:boolean;
    disabled?:boolean;
    errorData?: {
        error?:boolean;
        message?:string;
    }|null;
    // fieldNames?: any;
    // options?:any;
};
const AntInputBlock = ({
                           title = '',
                           antInputPlaceholder='',
                           antInputType='text',
                           antInputAllowClear=false,
                           disabled=false,
                           value=null,
                           defaultValue=null,
                           antInputCss = null,
                           antInputOnChange = null,
                           antInputOnBlur = null,
                           errorData = null,
                       }:Props) => {


    return (
        <BlockGroup
            {...antInputCss}
            error={errorData?.error ?? false}
        >
            <div className={'ant_input_title'}>{title}</div>
            <div className={'ant_input_container flex flex-row items-center justify-center'}>
                <div className={'ant_input_container00 flex flex-row items-center justify-center'}>

                    {antInputType === 'text' &&
                        <Input
                            style={{background:antInputCss?.background || 'rgba(38, 25, 68)'}}
                            bordered={false}
                            placeholder={antInputPlaceholder}
                            value={value}
                            onChange={antInputOnChange}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            onBlur={antInputOnBlur}
                            // allowClear={antInputAllowClear}
                            allowClear={!antInputAllowClear ? antInputAllowClear : {clearIcon:IconX}}
                        />

                    }
                    {antInputType === 'password' &&
                        <Input.Password
                            autoComplete="new-password"
                            visibilityToggle={true}
                            style={{background:antInputCss?.background || 'rgba(38, 25, 68)'}}
                            bordered={false}
                            placeholder={antInputPlaceholder}
                            disabled={disabled}
                            value={ value === null ?'': value}
                            onChange={antInputOnChange}
                            // allowClear={antInputAllowClear}
                            allowClear={!antInputAllowClear ? antInputAllowClear : {clearIcon:IconX}}
                            // iconRender={(visible) => (visible ? EyeOutlined : EyeInvisibleOutlined)}
                        />
                    }
                </div>
            </div>
            {errorData?.error && errorData?.message &&
                <div className={'ant_error'}>{errorData?.message}</div>
            }


        </BlockGroup>

    );
};

export default AntInputBlock;
