import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MetaBloxLogo from "@@/assets/metablox-logo.png"
import AlertImg from "@@/assets/icons/alert.png"
import HeadPic from "@@/assets/imgs/head_pic.png"
import IconArrowDown from "@@/assets/icons/menu_arrow_down.png"
import IconArrowUp from "@@/assets/icons/menu_arrow_up.png"
import {cacheUserInfo, clearAccount} from "@@/utils/function";
import Button from "@@/components/common/Button";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import YLine from "@@/assets/imgs/y-line.png";


// background: ${(props) => (props.type === 1 ? "#0f0035" : "#1F1F1F")};
// box-shadow: ${(props) => (props.type === 1 ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : null)};
export const HeaderWrapper = styled.div<{ type: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 120px 20px 48px;
  background: #0f0035;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  
  .main{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 27px;
    .YLine{
      width: 1px;
      height: 17px;
      //margin: 10px 40px 0 40px;
    }
    .system{
      color: #FFFFFFCC;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;

      background: linear-gradient(179.71deg, #EFCDFB 0.51%, #6A84FF 202.63%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
    }
    img {
      width: 177px;
    }
    .LateralNavigation{
      display: flex;
      flex-direction: row;
      gap: 48px;
      margin-left: 110px;
      font-size: 19px;
      line-height: 26px;
      color: #FFFFFF;

      .unActiveNavigation{
        opacity: 0.5;
        border-bottom: 3px solid #000000;
        padding-bottom: 5px;
        cursor: pointer;
      }
      
      .activeNavigation{
        opacity: 1;
        border-bottom: 3px solid;
        padding-bottom: 5px;
        border-image: linear-gradient(to right, #8f41e9, #578aef) 1;
      }
    }

  }
  

  .userInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .alertNavigation{
      margin-right: 90px;
      cursor: pointer;
      img{
        width: 18px;
      }
    }
    .userInfo_main{
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
    
    .userHeadPic{
      img{
        width: 48px;
        height: 48px;
        border-radius: 24px;
      }
    }
    .username{
      margin: 0 17px 0 12px;
      font-size: 23px;
      line-height: 31px;
      color: rgba(214, 204, 227, 1);
    }
    .userMenu{
      img{
        width: 16px;
      }

    }
  }
`;
export const DesktopMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 6%;
  top: 100px;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  border-radius: 8px;
  align-items: center;
  padding: 1em;
  z-index: 999;
  background: #0f0035;
  width: 300px;
  border: 1px solid #464255;

`;


type Props = {
  type?:number;
  setRefreshPage?:any;
  ActiveNav?:string;
};
const Header = ({setRefreshPage,ActiveNav = 'dashboard',type = 1}:Props) => {
  // const navList1 = [
  //   {title:'Dashboard',url:'/',nav:'dashboard'},
  //   {title:'Wi-Fi',url:'/wifi',nav:'wifi'},
  //   // {title:'My Business',url:'/',nav:'my_business'},
  //   // {title:'Operation',url:'/',nav:'operation'},
  //   // {title:'SubAccount',url:'/',nav:'sub_account'},
  //   {title:'Account',url:'/account',nav:'account'},
  // ]
  //
  // const navList0 = [
  //   {title:'Account',url:'/account',nav:'account'},
  // ]

  const [userInfo, setUserInfo] = useState<any>();
  const [openUserMenu, setOpenUserMenu] = useState(false);
  // const [navList, setNavList] = useState<any[]>(navList0);
  const navigate = useNavigate();


  // const windowReload = () => {
  //   window.location.reload()
  // }

  const logOut = () => {
    clearAccount()
    navigate('/login')
    // setRefreshPage?.(Date.now())
    // windowReload()
  }

  const goToMain = () => {

  }


  const initUser = async () => {
    setUserInfo(await cacheUserInfo())
    // if(await cacheApplyStatus() == 2){
    //   setNavList(navList1)
    // }

  }



  useEffect(() => {
    initUser()
  }, []);

  return (
    <HeaderWrapper type={type}>
      <div className="main">
        <img src={MetaBloxLogo} alt="MetaBloxLogo" onClick={() => goToMain()} />
        <img src={YLine} alt="YLine" className="YLine"/>
        <div className="system">Merchant</div>
      </div>
      <div className="userInfo">
        <div
            // data-tooltip-id="alertNavigation"
            // data-tooltip-content='aaaaaa'
            data-tooltip-id="alertNavigation"
            data-tooltip-content={'No Notification'}
            className="alertNavigation">
          <Tooltip id="alertNavigation" place="bottom" />

          <img src={AlertImg} alt="AlertImg" onClick={() => goToMain()} />
        </div>
        <div className="userInfo_main" onClick={()=>setOpenUserMenu(!openUserMenu)}>
          <div className="userHeadPic">
            <img src={userInfo?.headPic ?? HeadPic} alt="HeadPic" onClick={() => goToMain()} />
          </div>
          <div
              // data-tooltip-id="username"
              // data-tooltip-content={userInfo?.email}
              className="username">
            {userInfo?.email}
          </div>
          {/*<Tooltip id="username" place="bottom" />*/}
          <div className="userMenu">
            <img src={openUserMenu ? IconArrowUp : IconArrowDown} alt="IconArrowDown" onClick={() => goToMain()} />
          </div>
        </div>

      </div>

      <DesktopMenu isOpen={openUserMenu}>
        <div className="profileDropdown">
          <Button SubmitClick={logOut} cssStyle={{width:'250px',height:"20px",font_size:'16px',padding:'10px 0',margin:'30px 0 30px 0',line_height:'20px',border_radius:'8px'}} title={'Log out'}/>
        </div>
      </DesktopMenu>

    </HeaderWrapper>
  );
};

export default Header;
