import React, {useEffect, useState} from "react";
import BalanceCard from "@@/components/balance/BalanceCard";
import RewardsCard from "@@/components/rewards/RewardsCard";
import { merchantCurrency} from "@@/utils/function";
import {getBalanceHistory, getDashboardOverview} from "@@/utils/request/api";
import IconCaretDown from "@@/assets/icons/dashboardCaretDown.png";
import IconCaretUp from "@@/assets/icons/dashboardCaretUp.png";
import IconGift from "@@/assets/icons/dashboardGift.png";
import IconTrendingUp from "@@/assets/icons/dashboardTrendingUp.png";
import IconArrowRight from "@@/assets/icons/menu_arrow_right.png";
import IconArrowDown from "@@/assets/icons/menu_arrow_down.png";
import PageLayout from "@@/layout";


type Props = {};
const App = (props: Props) => {
    const [showBalance, setShowBalance] = useState(true);
    const [dashboardData, setDashboardData] = useState<any>();
    const [dataList,setDataList] = useState<any[]>([])

    const initUser = async () => {
    }

    const initDashboardData = async () => {
        const res = await getDashboardOverview()
        if(res?.code === 200){
            setDashboardData(res?.data ?? {})
        }
    }

    const getDataList = async () => {
        const res = await getBalanceHistory({page:1,size:2});
        res.total = res?.data?.total;
        res.data = res?.data?.list ?? [];
        if(res?.code === 200){
            setDataList(res?.data)
        }
    }

    useEffect(() => {
        initUser()
        initDashboardData();
        getDataList()

    }, []);

    return (
        <PageLayout>
            <div className="main_container flex flex-col gap-[20px]">
                <div className="title">Dashboard</div>
                <div className="card flex flex-row gap-[20px]">
                    <div  onClick={()=>setShowBalance(true)} className={`card_container flex flex-col gap-[15px] ${showBalance ? '':'card_grey'}`}>
                        <div className="header flex flex-row justify-between items-center">
                            <div className="header_left flex flex-row gap-[25px]">
                                <img src={IconTrendingUp} alt="header_left1" className="header_left1"/>
                                <div className="header_left2">Balance</div>
                            </div>
                            <div className={'header_right_div flex flex-row justify-center items-center'}>
                                <img src={showBalance ? IconArrowDown : IconArrowRight} alt="header_right" className={`header_right cursor-pointer ${showBalance ? 'header_right0': 'header_right' }`}/>
                            </div>

                        </div>
                        <div className="content flex flex-row gap-[10px]">
                            {/*<div className="content1">CAD </div>*/}
                            {/*<div className="content2">${(dashboardData?.creditBalance ?? 0).toFixed(2)}</div>*/}
                            <div className="content2">{(dashboardData?.creditBalance ?? 0).toFixed(2)} Points</div>
                        </div>
                        <div className="bottom flex flex-row justify-between items-center">
                            <div className="bottom_left flex flex-col gap-[15px]">
                                {dataList.map((item,index)=>(
                                    // <div key={index} className="data_item flex flex-row justify-between">
                                    //     <div className="left">Transaction ID#{item?.id}</div>
                                    //     <div className="right flex flex-row justify-between gap-[100px]">
                                    //         <div className={item?.amount >= 0 ? "right1 currency_green":"right1 currency_red"}>{item?.amount >= 0 ? "+":""}{item?.amount} {merchantCurrency()}</div>
                                    //         <div className="right2">{conversionUtcToDate(item?.createdAt)}</div>
                                    //     </div>
                                    // </div>
                                    <div key={index} className="transaction flex flex-row gap-[15px]">
                                        <div className="transaction_left">Transaction #{item?.id}</div>
                                        <div className="transaction_right flex flex-row gap-[5px]">
                                            <div className="transaction_right1">{item?.amount >= 0 ? "+":""}{item?.amount} {merchantCurrency()}</div>
                                            <img src={item?.amount >= 0 ?IconCaretUp:IconCaretDown} alt="transaction_right2" className="transaction_right2"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/*<img src={IconChart1} alt="bottom_right" className="bottom_right"/>*/}
                        </div>
                    </div>

                    <div onClick={()=>setShowBalance(false)} className={`card_container flex flex-col gap-[15px] ${showBalance ? 'card_grey':''}`}>
                        <div className="header flex flex-row justify-between items-center" >
                            <div className="header_left flex flex-row gap-[25px]">
                                <img src={IconGift} alt="header_left1" className="header_left1"/>
                                <div className="header_left2">Rewards</div>
                            </div>
                            <div className="header_center flex flex-row gap-[5px] items-center">
                                {/*<img src={IconOne} alt="header_left1" className="header_center1"/>*/}
                                {/*<div className="header_center2">ONE token</div>*/}
                            </div>
                            <div className={'header_right_div flex flex-row justify-center items-center'}>
                                <img src={showBalance ?  IconArrowRight: IconArrowDown} alt="header_right" className={`header_right cursor-pointer ${showBalance ? 'header_right': 'header_right0' }`}/>
                            </div>
                        </div>
                        <div className="content flex flex-row gap-[10px]">
                            {/*<div className="content1">CAD </div>*/}
                            {/*<div className="content2">${(dashboardData?.tokenBalance ?? 0).toFixed(2)}</div>*/}
                            <div className="content2">{(dashboardData?.tokenBalance ?? 0).toFixed(2)} Points</div>
                        </div>
                        <div className="bottom flex flex-row justify-between items-center">
                            <div className="bottom_left flex flex-col gap-[15px]">
                            </div>
                            {/*<img src={IconChart2} alt="bottom_right" className="bottom_right"/>*/}
                        </div>
                    </div>
                </div>

                <div className="detail">
                    {showBalance ? <BalanceCard /> : <RewardsCard />}
                </div>
            </div>
        </PageLayout>
  );
};
export default App
