import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PageLayout from "@@/layout";
import { Table} from 'antd';
import type { FilterValue,ColumnsType,TablePaginationConfig } from 'antd/es/table/interface';
import AntSearchInputBlock from "@@/components/common/AntSearchInput";
import { getGuestList} from "@@/utils/request/api";
import {conversionUtcToDate} from "@@/utils/function";
import toast from "react-hot-toast";

const TableGroup = styled.div`
  padding: 60px 120px 0 40px;

  .ant-input-affix-wrapper-focused {
    border-color: #e8e8e8;
    outline: 0;
    box-shadow:0 0 0 0!important;
  }
`;


interface DataType {
    id:number
    memberId: number
    email: string
    createdAt: string
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}
const columns: ColumnsType<DataType> = [
    {
        title: 'No.',
        render: (value, record, index) => `${index + 1}`,
        // dataIndex: 'id',
        // sorter: true,
        // render: (name) => `${name.first} ${name.last}`,
        width: '8%',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        width: '20%',
    },
    {
        title: 'Arrival Time',
        dataIndex: 'createdAt',
        render: (createdAt) => `${conversionUtcToDate(createdAt)}`,
        width: '20%',
    },
];

type Props = {};
const App = (props: Props) => {
    const [data, setData] = useState<DataType[]>();
    const [loading, setLoading] = useState(false);
    const [searchKeywords, setSearchKeywords] = useState();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });




    const handleInputEnter = () => {
        setSearchKeywords(searchKeywords)
    }

    const handleTableChange = (pagination:TablePaginationConfig,filters:Record<string, FilterValue|null>,sorter:any) => {
        console.log('pagination',pagination);
        console.log('filters',filters);
        console.log('sorter',sorter);
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            getGuestList({
                page:tableParams?.pagination?.current ?? 1,
                size:tableParams?.pagination?.pageSize ?? 10,
                email:searchKeywords
            }).then((results:any)=>{
                const code = results?.code;
                const total = results?.data?.total;
                const data = results?.data?.list?? [];
                if(code === 200){
                    setData(data);
                }
                else{
                    toast.error(results?.msg);
                }
                setLoading(false);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: total,
                    },
                });
            })
        };

        fetchData();
    }, [tableParams,searchKeywords]);

    return (
        <PageLayout activeNav="user">
            <TableGroup className="flex flex-col gap-[20px]">
                <div className="text-[#ffffff]">Guest List</div>
                <AntSearchInputBlock
                    antInputPlaceholder={'Search email'}
                    value={searchKeywords}
                    antInputOnChange={(item:any)=>setSearchKeywords(item.target.value.slice(0,50))}
                    antInputOnPressEnter={handleInputEnter}
                />

                <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </TableGroup>

        </PageLayout>
    );
};
export default App
