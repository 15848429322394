import React, {useEffect, useState} from "react";
import {ConfigProvider, Slider, Upload} from "antd";
import styled from "styled-components";
import Button from "@@/components/common/Button";
import toast from "react-hot-toast";
import IphoneImg from "@@/assets/imgs/iphone.png";
import {getWifiInfo, settingWiFi, uploadFile} from "@@/utils/request/api";
import {empty, form_validation, form_value_validation, randomNum} from "@@/utils/function";
import IconUser from "@@/assets/icons/account_user.png";
import Popup from "reactjs-popup";
import PopupAlert from "@@/pages/PopupAlert";
import ImgCrop from "antd-img-crop";
import {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import AntInputBlock from "@@/components/common/AntInputBlock";
import PageLayout from "@@/layout";
const formatter = (value: any) => value?`${value}hr`:'';
const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
  width: 100%;
  
  .FormContent{
    display: flex;
    flex-direction: column;
    //background-color: #06011D;
    background-color: #07001b;
    width: 100%;

    .ant_error{
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      padding-top: 6px;
      color: #FF0000;
    }

    .main_container{
      display: flex;
      flex-direction: column;
      gap:20px;
      margin: 60px 120px 0 40px;

      .title{
        font-size: 20px;
        line-height: 27px;
        font-weight: 800;
      }
      .account_status{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 57px;
        background: #201c31;
        border-radius: 8px;
        padding: 0 24px;

        .left{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;

          .left_icon{
            width: 24px;
            height: 24px;
          }

        }

        .right{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }

      .account_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        background: #110e21;
        border-radius: 8px;
        padding: 32px 0;
        
        .image_block{
          width: 492px;
          height: 180px;
          display: flex;
          flex-direction: column;
          margin: 0 0 6px 0;
          
          .title{
            //height: 21px;
         
            margin: 0 0 5px;
            
            .title_name{
              white-space:nowrap;
              margin-right: 10px;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: rgba(255, 255, 255, 0.8);
            }

            .tips{
              font-size: 13px;
              line-height: 16px;
              color: rgba(255, 255, 255, 0.78);
            }
          }
          
        }
        
        .timeout{
          width: 492px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .title{
            margin: 0 0 5px;
            .title_name{
              white-space:nowrap;
              margin-right: 10px;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: rgba(255, 255, 255, 0.8);
            }

            .tips{
              font-size: 13px;
              line-height: 16px;
              color: rgba(255, 255, 255, 0.78);
            }
          }

          .slider_left{
            margin-right: 50px;
          }

          .slider{
            width: 100%;
            padding: 0 15%;
            border: 1px solid #464255;
            border-radius: 8px;
          }
          .ant-slider .ant-slider-step{
            background: #D9D9D9;
            border-radius: 2px;
          }
          .ant-slider .ant-slider-track {
            background-color: #91caff;
            border-radius: 2px;
            transition: background-color 0.2s;
          }
          .ant-slider-mark-text{
            color: #ffffff;
          }

          .ant-tooltip .ant-tooltip-inner{
            background-color: #ffffff;
          }
      
        }
        
      }
    }
  }
  .PhoneShow{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #171427;
    width: 520px;
    min-height: 100vh;
    padding-top: 40px;
    gap: 20px;
    
    .phone_title{
      width: 200px;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
    }
    .phone_model{
      display: flex;
      flex-direction: column;
     
      .iphone_img{
        width: 220px;
      }
      .banner_img{
        width: 190px;
        height: 102px;
        position: relative;
        bottom: 376px;
        left: 16px;
      }
    }

  }
`;
type Props = {};
const App = (props: Props) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [popupMsg,setPopupMsg] = useState('')
    const antInputCss = {height:34,background:'#242034',background_image:'linear-gradient(324.05deg, #413e50 -8.98%, #413e50 31.12%, #413e50 98.56%)'}
    const btnStyle = {margin:'27px 0 0 0', padding:'10px 67px', width:'100%', font_size:'13px', line_height:'18px', font_weight:'400',background: '#267FF5',border_radius: '8px'}

    const openPopupAlert = (msg:string) => {
        setOpenPopup(true);
        setPopupMsg(msg)
    }


    const submitWiFi = async () => {
        const getInfo = async () => {
            const res = await getWifiInfo();
            if(res?.code !== 200){
                openPopupAlert(res?.msg ?? 'Error');
                return false;
            }

            !empty(res?.data?.banner) &&
            setFileBanner([{
                uid: randomNum(1000000000,9999999999).toString(),
                name: 'banner.png',
                status: 'done',
                url: res?.data?.banner
            }])

            const fdata = res?.data
            fdata.forwardLink = fdata.forwardLink ?? ''
            fdata.timeout = fdata.timeout ?? 1
            setFormData(fdata)
        }


        const {validate,validateData} = form_validation(formData,form1Rules)
        console.log('validateData',validateData);
        if(!validate){
            toast.error('Error! A problem has been occurred while submitting your data.');
            setFormErrorData(validateData)
            return false
        }
        const res = await settingWiFi(formData);
        if(res?.code !== 200){
            toast.error(res?.msg ?? 'Error');
            return false;
        }
        openPopupAlert('Save successfully');
        await getInfo();
    }



    type PropsFormData = {
        [key: string]: any;
    };
    const initFormData = {
        banner:'',
        forwardLink:'',
        timeout:'',
    }
    const form1Rules:PropsFormData = {
        banner:[
            {required:true,message:'Please provide your banner image to complete the registration.'},
        ],
        forwardLink:[
            {reg:/^(http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/,message:'The store link provided is not in a valid format. Please enter a valid store link.'},
            {maxLen:300,message:'The store link should not exceed 300 characters. Please shorten it.'},
        ],
        timeout:[
            {required:true,message:'The timeout field is required. Please select timeout.'},
        ],
    }

    const [editNum, setEditNum] = useState(1);
    const [editNum1, setEditNum1] = useState(1);
    const [formData, setFormData] = useState<PropsFormData>(initFormData);
    const [formErrorData, setFormErrorData] = useState<PropsFormData>({})
    const [fileBanner, setFileBanner] = useState<UploadFile[]>([]);
    const onChangeUploadLogo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileBanner(newFileList);
    };
    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const beforeUploadFile = (file: RcFile) => {
        const isAllowType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isAllowType) {
            toast.error('You can only upload JPG/PNG file!');
            return Upload.LIST_IGNORE
        }
        const isAllowSize = file.size / (1024*1024) < 1;
        if (!isAllowSize) {
            toast.error('The file size exceeds the limit. Please select a file that is within 1MB for uploading. ');
            return Upload.LIST_IGNORE
        }
        return isAllowType && isAllowSize;
    };

    const validateFormValue = (key:string) => {
        const data = formErrorData;
        data[key] = form_value_validation(formData[key],form1Rules[key] ?? [])
        setFormErrorData(data)
        setEditNum1(editNum1+1)
    }
    const resetFormErrorDataValue = (key:string) => {
        const data = formErrorData;
        data[key] = {error:false,message:''}
        setFormErrorData(data)
        setEditNum1(editNum1+1)
    }
    const setFormValue = (key:any,value:any) => {
        resetFormErrorDataValue(key)
        const form = formData;
        form[key] = value;
        setFormData(form)
        setEditNum(editNum+1)
    }

    const handleUploadFile = async (options:any,file='logo') => {
        let params = new FormData();
        params.append("file", options.file);
        params.append("content_type", 'multipart/form-data');
        const res1 = await uploadFile(params);
        if(res1?.code !== 200){
            toast.error(res1?.msg ?? 'Failed to upload');
            return false;
        }
        const imgurl = res1?.data?.url;
        if( !imgurl || imgurl?.length <= 0 ){
            toast.error('Banner url does not exist!');
            return false;
        }

        options.onSuccess(res1, options.file);
        setFormValue('banner',imgurl)
    }


    useEffect(() => {
        const getInfo = async () => {
            const res = await getWifiInfo();
            if(res?.code !== 200){
                openPopupAlert(res?.msg ?? 'Error');
                return false;
            }

            !empty(res?.data?.banner) &&
            setFileBanner([{
                uid: randomNum(1000000000,9999999999).toString(),
                name: 'banner.png',
                status: 'done',
                url: res?.data?.banner
            }])

            const fdata = res?.data
            fdata.forwardLink = fdata.forwardLink ?? ''
            fdata.timeout = fdata.timeout ?? 1
            setFormData(fdata)
        }

        getInfo()
    }, []);
    return (
        <PageLayout
            activeNav={'wifi'}
        >
            <Popup
                open={openPopup}
                closeOnDocumentClick={false}
                onClose={()=>setOpenPopup(false)}
                overlayStyle = {{ background: 'rgba(0,0,0,0.8)' }}
            >
                <PopupAlert closePopup={()=>setOpenPopup(false)} msgContent={popupMsg}/>
            </Popup>
            <FormContent>
                <div className='FormContent'>
                    <div className="main_container">
                        <div className="title">WiFi Settings</div>
                        <div className="account_status">
                            <div className="left">
                                <img src={IconUser} alt="left_icon" className="left_icon"/>

                                <div className="label">Manage your wifi settings </div>
                            </div>

                        </div>

                        <div className="account_content">

                            <div className="image_block">
                                <div className="title flex flex-row">
                                    <div className='title_name flex flex-row'>Banner *</div>
                                    <div className='tips'>Use the 'Banner' feature to personalize your store's look and feel. By uploading a custom banner image, you can enhance the user experience. Preview how the banner will appear on a mobile phone on the right side of this page. </div>
                                </div>
                                <div className="image_show flex flex-row">
                                    <ImgCrop
                                        modalTitle={'Crop Picture'}
                                        aspect={1200/645}
                                        rotationSlider>
                                        <Upload
                                            beforeUpload={beforeUploadFile}
                                            listType="picture-card"
                                            fileList={fileBanner}
                                            customRequest={(options)=>handleUploadFile(options)}
                                            onChange={onChangeUploadLogo}
                                            onPreview={onPreview}
                                            maxCount={1}
                                            multiple={false}
                                            onRemove={()=>{
                                                setFormValue('banner','')
                                            }}
                                        >
                                            {fileBanner.length < 1 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>

                                    {/*<div className='tips'>*/}
                                    {/*    Use the 'Upload Banner' feature to personalize your store's look and feel. By uploading a custom banner image, you can enhance the user experience. Preview how the banner will appear on a mobile phone on the right side of this page.*/}
                                    {/*</div>*/}
                                </div>
                                {formErrorData.banner?.error && formErrorData.banner?.message &&
                                    <div className={'ant_error'}>{formErrorData.banner?.message}</div>
                                }
                            </div>

                            <AntInputBlock
                                title={'Store Link ( Optional )'}
                                antInputPlaceholder={'Please enter Store Link'}
                                antInputCss={antInputCss}
                                antInputOnChange={(item:any)=>setFormValue('forwardLink',item.target.value.slice(0,300))}
                                value={formData.forwardLink}
                                antInputAllowClear={true}
                                antInputOnBlur={()=>validateFormValue('forwardLink')}
                                errorData={formErrorData.forwardLink}
                            />

                            <div className="timeout">
                                <div className="title flex flex-row">
                                    <div className='title_name flex flex-row'>Timeout *</div>
                                    <div className='tips'>The 'Timeout' feature automatically logs out users or disconnects them from the network after a specified period of inactivity. This promotes both security by preventing unauthorized access and efficiency by managing resources.</div>
                                </div>
                                <div className="slider">
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: '#ffffff',
                                                colorTextBase:'#4B35ABFF',
                                                colorBgBase:'#267FF5',
                                            },
                                        }}
                                    >
                                        <Slider
                                            onChange={(value)=> setFormValue('timeout',value)}
                                            tooltip={{ formatter,open:true }}
                                            min={1}
                                            max={24}
                                            marks={{
                                                1: '1hr',
                                                12: '12hr',
                                                24: '24hr',
                                            }}
                                            style={{ marginTop: 40}}
                                            value={formData.timeout}
                                            defaultValue={formData.timeout} />
                                    </ConfigProvider>

                                </div>
                            </div>

                            <div className="btn">
                                <Button SubmitClick={submitWiFi} cssStyle={btnStyle} title={"Save"}/>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='PhoneShow'>
                    <div className="phone_title">
                        How it looks on mobile phone
                    </div>
                    <div className="phone_model">
                        <img src={IphoneImg} alt="IphoneImg" className="iphone_img"/>
                        <div>
                            {formData.banner && <img src={formData.banner} alt="BannerImg"  className="banner_img"/>}
                        </div>
                    </div>
                </div>
            </FormContent>
        </PageLayout>

    );
};

export default App
